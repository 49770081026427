import React from 'react';
import { Col } from 'react-bootstrap';

export default class ClGrid extends React.Component {

    getIcon = (key) => {
        switch(key) {
            default: return (<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.663 35.006V9.996h-6.67c-.92 0-1.667.746-1.667 1.667v23.343M36.674 35.006V11.663c0-.92-.747-1.667-1.668-1.667h-6.67v25.01M16.665 30.004h6.67M17.499 35.006v-5.002h5.002v5.002M15.832 25.002h8.336M15.832 20h8.336M7.495 22.501h4.168M7.495 28.337h4.168M7.495 16.665h4.168M28.337 22.501h4.168M28.337 28.337h4.168M28.337 16.665h4.168" stroke="#534646" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M28.337 35.006V6.728a1.702 1.702 0 0 0-1.668-1.734H13.331a1.702 1.702 0 0 0-1.668 1.734v28.278" stroke="#535346" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M38.341 35.006H1.659" stroke="#534646" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <circle cx="20" cy="12.497" r="2.501" stroke="#534646" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>);
            case "designer": return (<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="m25.617 32.015 6.399-6.399M7.786 14.453l6.667-6.667M20.473 13.807l-7.83-7.83a3.333 3.333 0 0 0-4.714 0L5.976 7.928a3.333 3.333 0 0 0 0 4.714l8.177 8.177M26.193 19.526l4.844 4.844a6.667 6.667 0 0 1 1.61 2.606l2.309 6.926a.833.833 0 0 1-1.054 1.054l-6.926-2.309a6.664 6.664 0 0 1-2.606-1.61l-5.19-5.191" stroke="#534646" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.3 25.067a51.344 51.344 0 0 1 8.332-10.434c6.718-6.718 13.448-10.88 15.03-9.297 1.584 1.584-2.578 8.313-9.296 15.03A51.325 51.325 0 0 1 14.932 28.7" stroke="#534646" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path clip-rule="evenodd" d="m11.61 33.814-4.537 1.134a1.667 1.667 0 0 1-2.022-2.022l1.137-4.547a4.462 4.462 0 0 1 4.338-3.38h.012A4.472 4.472 0 0 1 15 29.473v0a4.477 4.477 0 0 1-3.39 4.34z" stroke="#534646" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>);
            case "buyer": return (<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path clip-rule="evenodd" d="M30.107 11.667H9.893c-.878 0-1.605.68-1.663 1.555l-1.327 19.89a3.334 3.334 0 0 0 3.327 3.555h19.54a3.334 3.334 0 0 0 3.327-3.555l-1.327-19.89a1.666 1.666 0 0 0-1.663-1.555z" stroke="#534646" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M25.002 15V8.333v0a5 5 0 0 0-5-5H20a5 5 0 0 0-5 5V15M16.667 20v1.667M23.333 20v1.667M25.833 27.813S23.645 30 20 30c-3.647 0-5.833-2.187-5.833-2.187" stroke="#534646" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>);
            case "private": return (<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.996 16.665V8.33a3.335 3.335 0 0 1 3.335-3.335h18.34a3.335 3.335 0 0 1 3.335 3.335v23.343a3.335 3.335 0 0 1-3.334 3.334h-6.67M16.665 11.663h11.672M20 18.333h8.337M28.337 25.002h-3.335" stroke="#534646" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path clip-rule="evenodd" d="M8.23 34.965H5.926a.932.932 0 0 1-.932-.932v0-7.307a.932.932 0 0 1 .932-.932H8.23a.949.949 0 0 1 .934.964v7.336a.92.92 0 0 1-.934.87v0z" stroke="#534646" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="m9.162 27.77 3.081-5.03a2.251 2.251 0 0 1 4.169 1.167v2.667h2.518c.756 0 1.462.38 1.88 1.01.372.56.48 1.254.296 1.9l-1.221 4.272a1.68 1.68 0 0 1-1.614 1.217h-5.97c-.488 0-.96-.17-1.335-.48l-1.804-1.501" stroke="#534646" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>);
        }
    }

    render() {
      return <Col md={1} className="for-whom-grid">
        {this.getIcon(this.props.icon)}
        <p itemprop="headline">{this.props.title}</p>
        <span itemprop="articleBody">{this.props.desc}</span>
      </Col>;
    }
  }