import React from 'react';
import { Modal, Row, Col, Container } from 'react-bootstrap';
import 'react-alice-carousel/lib/alice-carousel.css';

export default class Works extends React.Component {
    render() {
      return <Modal {...this.props} fullscreen={true} animation={false} className="my-modal" onHide={this.props.hide}>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <Container>
            <Row>
                <Col>
                    Скоро я добавлю свои работы сюда!
                </Col>
            </Row>
        </Container>
      </Modal.Body>
    </Modal>;
    }
  }