import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

export default class Service extends React.Component {

    constructor(props){
      super(props)
      this.state = {
        title: this.props.title,
        desc: this.props.desc.replaceAll('&#77#&', '<br/><br/>'),
        price: this.props.price,
        img: this.props.image
      }
      console.log(this.props.image)
    }

    render() {
      return <><Col md={6} sm={12} itemscope itemtype="http://schema.org/NewsArticle">
        <Row className='title'>
          <Col>
          <span>
            <span class="title-style" itemprop="headline">{this.state.title}</span>
          </span>
          </Col>
        </Row>
        <Row className='description'>
          <Col itemprop="articleBody" className="description-col" dangerouslySetInnerHTML={{__html: this.state.desc}}>
            {/* {this.state.desc} */}
          </Col>
        </Row>
        <Row className='price'>
          <Col>Стоимость работ от {this.state.price} ₽</Col>
        </Row>
        <Row>
          <Col>
            <Button variant="sec" onClick={() => this.props.target(this.state.title)}>Посмотреть примеры работ</Button>
          </Col>
        </Row>
      </Col>
      <Col md sm></Col>
      <Col md="auto" sm={12}>
        <Row className={`${this.state.img} justify-content-end`}>
        </Row>
      </Col></>;
    }
  }