import React from 'react';

export default class ArrowL extends React.Component {

    arrowClick = (event) => {
      this.props.target(this.props.direction)
    }

    render() {
      return <div className={"arrow " + (this.props.direction === 'left' ? 'arrow-rotate' : '')} onClick={e => this.arrowClick(this.props.direction)}>
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="m35.461 29.509 10.18 10.467-10.18 10.515L36.898 52l11.64-12-11.616-12-1.46 1.509z" fill="#635757"/>
        </svg>
      </div>;
    }
  }