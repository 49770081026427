import React from 'react';
import { Col } from 'react-bootstrap';

export default class Grid extends React.Component {
    render() {
      return <Col md={3} className="plan-grid-col">
        <p itemprop="headline">{this.props.title}</p>
        <span itemprop="articleBody">{this.props.desc}</span>
      </Col>;
    }
  }