import React from 'react';
import { Card, Button } from 'react-bootstrap';

export default class ServiceTouch extends React.Component {

    constructor(props){
      super(props)
      this.state = {
        title: this.props.title,
        desc: this.props.desc.replaceAll('&#77#&', '<br/><br/>'),
        price: this.props.price,
        img: this.props.image
      }
    }

    getImage = (key) => {
      switch (key) {
        default: return require('./imgs/i1.png');
        case 'img2': return require('./imgs/i2.png');
        case 'img3': return require('./imgs/i1.png');
        case 'img4': return require('./imgs/i1.png');
      }
    }

    render() {
      return <Card style={{ marginTop: '20px', border: 'None', borderRadius: '16px', backgroundColor:"#FCF7F2" }}>
        {/* <Card.Title className="title">{this.state.title}</Card.Title> */}
        <Card.Img variant="top" src={this.getImage(this.state.img)} />
        <Card.Body>
          <Card.Text className='description-col' dangerouslySetInnerHTML={{__html: this.state.desc}}></Card.Text>
          <Card.Text className='price'>Стоимость работ от {this.state.price} ₽</Card.Text>
          <div className="d-grid gap-2">
            <Button variant="sec" size="lg" onClick={() => this.props.target(this.state.title)}>Посмотреть примеры работ</Button>
          </div>
        </Card.Body>
      </Card>;
    }
  }